<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <br />

        <GMapMap
          :center="{ lat: 13.736717, lng: 100.523186 }"
          :zoom="8"
          map-type-id="terrain"
          ref="gmap"
          style="width: 100%; height: 800px"
          :options="{ fullscreenControl: true }"
        >
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            :icon="m.icon"
            @click="toggleInfoWindow(m, index)"
          >
            <GMapInfoWindow
              :closeclick="true"
              @closeclick="infoWinOpen = false"
              :opened="openedMarkerID === m._id"
            >
              <div class="" style="color: black">
                <div class="card-content">
                  <div>
                    {{ marker_data.category }}
                    <br />

                    <div class="">
                      <div class="">
                        <h3
                          class="text-2xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-700"
                        >
                          {{ marker_data.station_name }}
                        </h3>
                        <br />
                        <hr style="border: 0.5px solid #7ef024" />
                        <br />
                        {{ marker_data.address }}
                        <br />
                        <br />
                        <hr style="border: 0.5px solid #7ef024" />
                        <br />
                      </div>

                      <div>
                        <a
                          class=""
                          style="
                            font-weight: 600;
                            color: green;
                            font-size: 16px;
                          "
                        >
                          เวลาทำการ *
                        </a>

                        {{ marker_data.time_out }}
                        <br />
                        <br />
                        <a
                          class=""
                          style="
                            font-weight: 600;
                            color: green;
                            font-size: 16px;
                          "
                        >
                          โทร *
                        </a>

                        {{ marker_data.phone }}
                      </div>

                      <div class="">
                        <a
                          class=""
                          style="
                            font-weight: 600;
                            color: green;
                            font-size: 16px;
                          "
                        >
                          ประเภทหัวชาร์จ *
                        </a>

                        <div
                          class="flex items-center justify-start"
                          v-for="t in marker_data.power"
                          :key="t"
                        >
                          <img
                            v-if="t.name == 'AC Type 1'"
                            style="
                              border: 2px solid green;
                              border-radius: 50%;
                              padding: 2px;
                              margin: 1px;
                            "
                            src="@/assets/img/connectors/1.svg"
                          />

                          <img
                            v-if="t.name == 'AC Type 2'"
                            style="
                              border: 2px solid green;
                              border-radius: 50%;
                              padding: 2px;
                              margin-left: 2px;
                              margin: 1px;
                            "
                            src="@/assets/img/connectors/2.svg"
                          />

                          <img
                            v-if="t.name == 'DC CSS2'"
                            style="
                              border: 2px solid green;
                              border-radius: 50%;
                              padding: 2px;
                              margin-left: 2px;
                              margin: 1px;
                            "
                            src="@/assets/img/connectors/CCS2.svg"
                          />
                          <img
                            v-if="t.name == 'DC CHAdeMO'"
                            style="
                              border: 2px solid green;
                              border-radius: 50%;
                              padding: 2px;
                              margin-left: 2px;
                              margin: 1px;
                            "
                            src="@/assets/img/connectors/Chademo.svg"
                          />

                          <b
                            v-if="
                              t.name != 'AC GB/T' &&
                              t.name != 'DC CSS1 2' &&
                              t.name != 'DC GB/T'
                            "
                            style="
                              margin-left: 5px;
                              font-weight: 600;
                              color: green;
                            "
                            >{{ t.name }}
                            <b class="text-black">( {{ t.power }} kw)</b>
                          </b>
                        </div>

                        <div style="display: flex; justify-content: flex-end">
                          <div style="margin-right: 1%">
                            <div style="display: flex">
                              <a
                                target="_blink"
                                :href="
                                  'https://www.google.com/maps/dir/?api=1&origin=&destination=' +
                                  marker_data.position.lat +
                                  '%2C' +
                                  marker_data.position.lng +
                                  '&travelmode=driving'
                                "
                              >
                                <button
                                  class="w-42 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                                >
                                  Get Directions
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapMap>
      </div>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <footer>
          <StickFooter />
        </footer>
      </div>

      <div class="bg-gradient-to-r from-green-800 to-blue-900">
        <div
          class="flex flex-col justify-start flex-grow w-full text-yellow-50"
        >
          <div
            class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
          >
            <div class="relative flex py-5 items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>
            <div class="grid grid-cols-2 gap-6 lg:grid-cols-4">
              <div class="text-center w-40 sm:w-60 cursor-pointer">
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Affiliate
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Block
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  FAQ
                </p>
              </div>
              <div
                class="text-center w-40 sm:w-60 cursor-pointer text-gray-100 hover:text-purple-300"
              >
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Twitter
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Telegram
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Facebook
                </p>
              </div>
              <div
                class="text-center w-40 sm:w-60 cursor-pointer text-gray-100 hover:text-purple-300"
              >
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Terms
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Support
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Contract
                </p>
              </div>
              <div class="text-center w-40 sm:w-60 cursor-pointer">
                <p
                  class="text-sm font-medium tracking-widest text-gray-400 uppercase lg:text-base"
                >
                  English (US)
                </p>
              </div>
            </div>
            <div class="relative flex items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <span class="flex-shrink mx-4 text-gray-400">Supports</span>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <div class="flex flex-col items-center justify-center m-5">
              <div class="flex">
                <div class="grid grid-cols-2 gap-6 lg:grid-cols-5">
                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/bch.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/bnb.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/usdt.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/usdc.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/busd.svg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="relative flex items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <div class="flex flex-col items-center justify-center mt-3">
              <div class="flex">
                <img
                  class="h-20 mr-4 shadow"
                  src="https://voltz.io/img/VOLTZ1.90cf8b7b.png"
                />

                <img
                  class="h-20 mr-4 shadow"
                  src="https://www.365.stream/img/logo.64755377.svg"
                />

                <img class="h-20 mr-4 shadow" src="@/assets/logo.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";

import transactions from "./transactions_bet.vue";

import car from "@/api/car";

import { POOL_ADDRESS, VERSION } from "../../config";

export default {
  components: {
    OurTeam,
    StickFooter,
    ConnectWallet,
  },
  computed: mapGetters(["getStation"]),

  data() {
    return {
      version: VERSION,
      locations: [],
      openedMarkerID: null,

      center: { lat: 52.51195, lng: 6.089625 },
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      marker_data: {
        category: ["PTT"],
        _id: "6388809b74b7a51c48d19e3f",
        position: { lat: 13.564695563534597, lng: 100.27955669531795 },
        station_name: "A.C.T สาขามหาชัย",
        address: "64/101, ต.ท่าทราย, อ.เมือง, สมุทรสาคร 74000",
        electricity: null,
        time_out: "Working hours 09:00:20:00",
        status_station: "HAVE A FEE",
        phone: "02-026-6133",
        website: "-",
        typeChrage: [],
        icon: "/img/pin_ea.6c81ecee.svg",
        power: [
          { name: "AC Type 1", power: "56" },
          { name: "AC Type 2", power: "56" },
          { name: "AC GB/T", power: "56" },
          { name: "DC CSS1 2", power: "56" },
          { name: "DC CHAdeMO", power: "56" },
          { name: "DC CSS2", power: "56" },
          { name: "DC GB/T", power: "56" },
        ],
      },
      markers: [],

      classicModal: false,
      noticeModal: false,
      signupModal: false,
      Provider: [
        { name: "PEA VOLTA", status: true },
        { name: "GO TO-U", status: true },
        { name: "PTT", status: true },
        { name: "EAANYWHERE", status: true },
      ],
      Service_fee: [
        { name: "Public free", status: true },
        { name: "Have a fee", status: true },
      ],
      Charging_head: [
        { name: "AC Type 1", status: true },
        { name: "AC Type 2", status: true },
        { name: "AC GB/T", status: true },
        { name: "DC CSS1", status: true },
        { name: "DC CHAdeMO", status: true },
        { name: "DC CSS2", status: true },
        { name: "DC GB/T", status: true },
      ],
    };
  },

  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  methods: {
    ...mapActions(["fetchStation", "fetchCategory"]),

    async update() {
      this.fetchStation();
    },
    getMarkers(m) {
      var markers = [];

      for (const [k, v] of Object.entries(m)) {
        for (const data of v) {
          if (data.company == "GO TO-U") {
            markers.push({
              category: data.category,
              _id: data._id,
              position: {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              },
              station_name: data.station_name,
              address: data.address,
              electricity: data.electricity,
              time_out: data.time_out,
              status_station: data.status_station,
              phone: data.phone,
              website: data.website,
              typeChrage: data.typeChrage,
              icon: require("@/assets/img/svg/pin_gotou.svg"),
              power: data.power,
            });
          } else if (data.company == "EAANYWHERE") {
            markers.push({
              category: data.category,
              _id: data._id,
              position: {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              },
              station_name: data.station_name,
              address: data.address,
              electricity: data.electricity,
              time_out: data.time_out,
              status_station: data.status_station,
              phone: data.phone,
              website: data.website,
              typeChrage: data.typeChrage,
              icon: require("@/assets/img/svg/pin_ea.svg"),
              power: data.power,
            });
          } else if (data.company == "PTT") {
            markers.push({
              category: data.category,
              _id: data._id,
              position: {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              },
              station_name: data.station_name,
              address: data.address,
              electricity: data.electricity,
              time_out: data.time_out,
              status_station: data.status_station,
              phone: data.phone,
              website: data.website,
              typeChrage: data.typeChrage,
              icon: require("@/assets/img/svg/pin_ptt.svg"),
              power: data.power,
            });
          } else if (data.company == "PEA VOLTA") {
            markers.push({
              category: data.category,
              _id: data._id,
              position: {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              },
              station_name: data.station_name,
              address: data.address,
              electricity: data.electricity,
              time_out: data.time_out,
              status_station: data.status_station,
              phone: data.phone,
              website: data.website,
              typeChrage: data.typeChrage,
              icon: require("@/assets/img/svg/pin_volta.svg"),
              power: data.power,
            });
          }
        }
      }

      this.markers = markers;
    },

    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;

      this.openedMarkerID = marker._id;

      this.marker_data = marker;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    async filteringMap() {
      var Provider_list = this.Provider.filter((p) => {
        return p.status == true;
      });

      var service_fee_list = this.Service_fee.filter((p) => {
        return p.status == true;
      });

      var charging_head_list = this.Charging_head.filter((p) => {
        return p.status == true;
      });

      var markers = [];

      for (const [k, v] of Object.entries(this.getStation)) {
        for (const data of v) {
          Provider_list.map((p) => {
            if (p.status == true && p.name == data.company) {
              if (data.company == "GO TO-U") {
                markers.push({
                  category: data.category,
                  _id: data._id,
                  position: {
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  },
                  station_name: data.station_name,
                  address: data.address,
                  electricity: data.electricity,
                  time_out: data.time_out,
                  status_station: data.status_station,
                  phone: data.phone,
                  website: data.website,
                  typeChrage: data.typeChrage,
                  icon: require("@/assets/img/svg/pin_gotou.svg"),
                  power: data.power,
                });
              } else if (data.company == "EAANYWHERE") {
                markers.push({
                  category: data.category,
                  _id: data._id,
                  position: {
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  },
                  station_name: data.station_name,
                  address: data.address,
                  electricity: data.electricity,
                  time_out: data.time_out,
                  status_station: data.status_station,
                  phone: data.phone,
                  website: data.website,
                  typeChrage: data.typeChrage,
                  icon: require("@/assets/img/svg/pin_ea.svg"),
                  power: data.power,
                });
              } else if (data.company == "PTT") {
                markers.push({
                  category: data.category,
                  _id: data._id,
                  position: {
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  },
                  station_name: data.station_name,
                  address: data.address,
                  electricity: data.electricity,
                  time_out: data.time_out,
                  status_station: data.status_station,
                  phone: data.phone,
                  website: data.website,
                  typeChrage: data.typeChrage,
                  icon: require("@/assets/img/svg/pin_ptt.svg"),
                  power: data.power,
                });
              } else if (data.company == "PEA VOLTA") {
                markers.push({
                  category: data.category,
                  _id: data._id,
                  position: {
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  },
                  station_name: data.station_name,
                  address: data.address,
                  electricity: data.electricity,
                  time_out: data.time_out,
                  status_station: data.status_station,
                  phone: data.phone,
                  website: data.website,
                  typeChrage: data.typeChrage,
                  icon: require("@/assets/img/svg/pin_volta.svg"),
                  power: data.power,
                });
              }
            }
          });
        }
      }

      var all_markers = [];

      markers.map((m) => {
        service_fee_list.map((s) => {
          if (
            m.status_station.toLocaleLowerCase() == s.name.toLocaleLowerCase()
          ) {
            all_markers.push(m);
          }
        });
      });

      markers = [];

      var all_markers_2 = [];

      all_markers.map((m) => {
        charging_head_list.map((s) => {
          if (m.typeChrage.includes(s.name)) {
            if (!all_markers_2.includes(m)) {
              all_markers_2.push(m);
            }
          }
        });
      });

      all_markers = [];

      this.markers = all_markers_2;
      this.signupModal = false;
    },
  },

  created() {
    this.update();
    this.getMarkers(this.getStation);
  },
};
</script>
